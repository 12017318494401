import React, { useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { ResponsiveLine } from '@nivo/line';

const Chart = () => {
	useEffect(() => {}, []);

	let data = [
		{
			id: 'japan',
			color: 'hsl(102, 70%, 50%)',
			data: [
				{
					x: 'plane',
					y: 51
				},
				{
					x: 'helicopter',
					y: 101
				},
				{
					x: 'boat',
					y: 63
				},
				{
					x: 'train',
					y: 299
				},
				{
					x: 'subway',
					y: 83
				},
				{
					x: 'bus',
					y: 210
				},
				{
					x: 'car',
					y: 255
				},
				{
					x: 'moto',
					y: 85
				},
				{
					x: 'bicycle',
					y: 92
				},
				{
					x: 'horse',
					y: 42
				},
				{
					x: 'skateboard',
					y: 97
				},
				{
					x: 'others',
					y: 161
				}
			]
		},
		{
			id: 'france',
			color: 'hsl(288, 70%, 50%)',
			data: [
				{
					x: 'plane',
					y: 291
				},
				{
					x: 'helicopter',
					y: 31
				},
				{
					x: 'boat',
					y: 283
				},
				{
					x: 'train',
					y: 30
				},
				{
					x: 'subway',
					y: 78
				},
				{
					x: 'bus',
					y: 271
				},
				{
					x: 'car',
					y: 161
				},
				{
					x: 'moto',
					y: 60
				},
				{
					x: 'bicycle',
					y: 247
				},
				{
					x: 'horse',
					y: 109
				},
				{
					x: 'skateboard',
					y: 230
				},
				{
					x: 'others',
					y: 168
				}
			]
		},
		{
			id: 'us',
			color: 'hsl(127, 70%, 50%)',
			data: [
				{
					x: 'plane',
					y: 168
				},
				{
					x: 'helicopter',
					y: 148
				},
				{
					x: 'boat',
					y: 227
				},
				{
					x: 'train',
					y: 173
				},
				{
					x: 'subway',
					y: 39
				},
				{
					x: 'bus',
					y: 84
				},
				{
					x: 'car',
					y: 263
				},
				{
					x: 'moto',
					y: 140
				},
				{
					x: 'bicycle',
					y: 163
				},
				{
					x: 'horse',
					y: 3
				},
				{
					x: 'skateboard',
					y: 63
				},
				{
					x: 'others',
					y: 60
				}
			]
		},
		{
			id: 'germany',
			color: 'hsl(330, 70%, 50%)',
			data: [
				{
					x: 'plane',
					y: 286
				},
				{
					x: 'helicopter',
					y: 298
				},
				{
					x: 'boat',
					y: 47
				},
				{
					x: 'train',
					y: 225
				},
				{
					x: 'subway',
					y: 113
				},
				{
					x: 'bus',
					y: 140
				},
				{
					x: 'car',
					y: 231
				},
				{
					x: 'moto',
					y: 270
				},
				{
					x: 'bicycle',
					y: 155
				},
				{
					x: 'horse',
					y: 79
				},
				{
					x: 'skateboard',
					y: 65
				},
				{
					x: 'others',
					y: 206
				}
			]
		},
		{
			id: 'norway',
			color: 'hsl(316, 70%, 50%)',
			data: [
				{
					x: 'plane',
					y: 246
				},
				{
					x: 'helicopter',
					y: 105
				},
				{
					x: 'boat',
					y: 19
				},
				{
					x: 'train',
					y: 231
				},
				{
					x: 'subway',
					y: 33
				},
				{
					x: 'bus',
					y: 235
				},
				{
					x: 'car',
					y: 120
				},
				{
					x: 'moto',
					y: 280
				},
				{
					x: 'bicycle',
					y: 293
				},
				{
					x: 'horse',
					y: 219
				},
				{
					x: 'skateboard',
					y: 168
				},
				{
					x: 'others',
					y: 3
				}
			]
		}
	];
	return (
		<div>
			<Jumbotron>
				<h1>[Chart Title]</h1>

				<div
					style={{
						height: 600,
						maxWidth: 1200,
						margin: 'auto'
					}}>
					<ResponsiveLine
						data={data}
						margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
						xScale={{ type: 'point' }}
						yScale={{
							type: 'linear',
							min: 'auto',
							max: 'auto',
							stacked: true,
							reverse: false
						}}
						axisTop={null}
						axisRight={null}
						axisBottom={{
							orient: 'bottom',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'transportation',
							legendOffset: 36,
							legendPosition: 'middle'
						}}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'count',
							legendOffset: -40,
							legendPosition: 'middle'
						}}
						colors={{ scheme: 'nivo' }}
						pointSize={10}
						pointColor={{ theme: 'background' }}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor', modifiers: [] }}
						pointLabel='y'
						pointLabelYOffset={-12}
						useMesh={true}
						legends={[
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 100,
								translateY: 0,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground:
												'rgba(0, 0, 0, .03)',
											itemOpacity: 1
										}
									}
								]
							}
						]}
					/>
				</div>
				<br />
				<br />
				<br />
				<p
					className='small'
					style={{
						maxWidth: 600,
						textAlign: 'center',
						margin: 'auto'
					}}>
					Data Citation: Lawrimore, Jay H.; Ray, Ron; Applequist,
					Scott; Korzeniewski, Bryant; Menne, Matthew J. (2016):
					Global Summary of the Year (GSOY), Version 1. [indicate
					subset used]. NOAA National Centers for Environmental
					Information. https://doi.org/10.7289/JWPF-Y430. May 5, 2020.
				</p>
			</Jumbotron>
		</div>
	);
};

export default Chart;
