import React from 'react';
import './App.css';
import Chart from './components/LineChart/Chart';
import Container from 'react-bootstrap/Container';

function App() {
	return (
		<div className='App'>
			<h1>Data Visualization</h1>
			<br />
			<Container>
				<Chart />
			</Container>
		</div>
	);
}

export default App;
